/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from "react";
// Prime Component

// plugins
import { useTranslation } from "react-i18next";

/*Component*/
import ContentLoading from "../loading/ContentLoading";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";

/*Service*/
import { CustomersImportPost, CustomerCreateAll } from "../../service/customer"; //Dökümanı yükle ve json olarak sonucu al
import { PersonnelGetList } from "../../service/personnel";
import { LeadGetList } from "../../service/lead";

const AddBulkCustomers = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef(null);
  const [data, setData] = useState(null);
  const [personnelList, setPersonnelList] = useState(null); //Personel Listesini Alır
  const [leadList, setleadListList] = useState(null); //Lead listesini alır
  const [selectedPersonnel, setSelectedPersonnel] = useState(null); //Seçilen Personeli Tutar
  const [selectedLeadState, setSelectedLeadState] = useState(null); //Seçilen Lead Durumunu Tutar
  const [selectedProducts7, setSelectedProducts7] = useState(null); //Checkbox İlle Seçilen Elemenları tutar
  const [advertisementChecked, setAdvertisementChecked] = useState(false); //Reklam datalarını seçmek için

  useEffect(() => {
    PersonnelGetList().then((res) => {
      //Personel Listesini Getirelim
      setPersonnelList(res);
      LeadGetList().then((res) => {
        //Lead Listesini Getirelim
        setleadListList(res);
      });
    });
  }, []);

  const fileUpload = (e) => {
    setIsLoading(true);
    CustomersImportPost(e.target.files[0])
      .then((res) => {
        setIsLoading(false);
        setData(res);
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Döküman Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };
  //Dropdown Search Code Start
  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="country-item country-item-value">
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };
  const countryOptionTemplate = (option) => {
    return (
      <div className="country-item">
        <div>{option.name}</div>
      </div>
    );
  };

  const customerPhoneBodyTemplate = (rowData) => {
    //Listeleme Telefon gösterimi
    return rowData.phoneNumberList.map((item, i) => {
      return (
        <span key={i}>
          {item.phoneNumber}
          <br></br>
        </span>
      );
    });
  };

  const save = () => {
    setIsLoading(true);

    selectedProducts7.map((item, i) => {
      item.leadState = selectedLeadState;
      item.user = selectedPersonnel;
    });

    let _data = data.filter((val) => !selectedProducts7.includes(val)); //Ham data ile seçilen dataları karşılaştırıp çıkarlalım.

    CustomerCreateAll(selectedProducts7, advertisementChecked)
      .then((res) => {
        //Seçilenleri kaydetmek için gönderelim
        setIsLoading(false);
        setData(_data); //Çıkarılan datalardan sonra ana datayı güncelleyelim
        toast.current.show({
          severity: "success",
          summary: "Başarılı",
          detail: "Başarılı Şekilde Kayıtlar Eklendi",
          life: 3000,
        });
      })
      .catch((err) => {
        setIsLoading(false);

        toast.current.show({
          severity: "error",
          summary: "Hata",
          detail: `${err.response.data}`,
          life: 3000,
        });
      });
  };

  return (
    <>
      {isLoading ? (
        <div className="grid">
          <div className="col-12">
            <div className="card">
              <ContentLoading />
            </div>
          </div>
        </div>
      ) : (
        <div className="grid p-fluid">
          <Toast ref={toast} />
          <div className="col-12">
            <div className="card">
              <h5>{t("topluMusteriEkle.topluMusteriEkleBaslik")}</h5>
              <div className="col-12">
                <input
                  type="file"
                  name="file"
                  className="form-control"
                  value=""
                  onChange={(e) => fileUpload(e)}
                  placeholder=""
                />
              </div>
              <div className="col-12">
                <div className="grid">
                  <div className="col-12 md:col-3">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("topluMusteriEkle.sorumluPersonel")}
                      </label>
                      <Dropdown
                        value={selectedPersonnel}
                        options={personnelList}
                        onChange={(e) => setSelectedPersonnel(e.value)}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-3">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("topluMusteriEkle.leadDurumu")}
                      </label>
                      <Dropdown
                        value={selectedLeadState}
                        options={leadList}
                        onChange={(e) => setSelectedLeadState(e.value)}
                        optionLabel="name"
                        filter
                        showClear
                        filterBy="name"
                        placeholder={t("global.placeholderSelect")}
                        valueTemplate={selectedCountryTemplate}
                        itemTemplate={countryOptionTemplate}
                      />
                    </div>
                  </div>
                  <div className="col-12 md:col-3">
                    <div className="field">
                      <label htmlFor="personnelEMail">
                        {t("topluMusteriEkle.reklamDatasiTitle")}
                      </label>

                      <div className="field-checkbox">
                        <Checkbox
                          inputId="binary"
                          checked={advertisementChecked}
                          onChange={(e) => {
                            setAdvertisementChecked(e.checked);
                          }}
                        />
                        <label htmlFor="binary">
                          {t("topluMusteriEkle.reklamDatasi")}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 md:col-3">
                    <div className="field" style={{ marginTop: 5 }}>
                      <Button
                        label={t("topluMusteriEkle.aktar")}
                        onClick={save}
                        className="p-button-success mt-4"
                      />
                    </div>
                  </div>
                  <div id="" class="col-md-12 mb-3">
                    <p>
                      {t("topluMusteriEkle.ornekExcellText")} <a href="https://docs.google.com/spreadsheets/d/16YO8T25WhMLpQLdWs4H9uCw38jwmPUVz/edit#gid=927518632" target="_blank">{t("topluMusteriEkle.tiklayiniz")}</a>
                    </p>
                    <p>
                      {t("topluMusteriEkle.YuklemeUyari")}
                    </p>
                  </div>
                </div>
                <DataTable
                  value={data}
                  selection={selectedProducts7}
                  onSelectionChange={(e) => setSelectedProducts7(e.value)}
                  selectionPageOnly
                  dataKey="excelOrder"
                  responsiveLayout="scroll"
                  emptyMessage={t("global.tableNoData")}
                  paginator
                  rows={10}
                  rowsPerPageOptions={[
                    10, 20, 30, 40, 50, 100, 150, 200, 250, 300,
                  ]}
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                  ></Column>
                  <Column
                    field="excelOrder"
                    header={t("topluMusteriEkle.sira")}
                  ></Column>
                  <Column
                    field="name"
                    header={t("topluMusteriEkle.adi")}
                  ></Column>
                  <Column
                    field="surname"
                    header={t("topluMusteriEkle.soyadi")}
                  ></Column>
                  <Column
                    body={customerPhoneBodyTemplate}
                    header={t("topluMusteriEkle.telefon")}
                  ></Column>
                </DataTable>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(AddBulkCustomers, comparisonFn);
